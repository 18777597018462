<template>
  <div class="collapse mb-5 pb-5">
    <div
      class="columns is-mobile"
      @click="isExpanded = !isExpanded"
    >
      <DashboardSearchOption
        class="column"
        v-bind="{ option: selectedOption }"
      />
      <div class="column is-narrow">
        <i
          class="fas fa-chevron-down"
          :class="[isExpanded ? 'is-up' : 'is-down']"
        />
      </div>
    </div>
    <ul v-if="isExpanded">
      <div
        v-for="(option, idx) in dropdownOptions"
        :key="idx"
      >
        <li
          v-if="!option.isSelected"
          :class="{ 'pb-5': !idx }"
        >
          <a @click="toggle(option.action)">
            <DashboardSearchOption v-bind="{ option }" />
          </a>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DashboardSearchOptions',
  components: {
    DashboardSearchOption: () => import('./DashboardSearchOption')
  },
  data: () => ({
    isExpanded: false
  }),
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', ['isCompanionUser', 'hasAutoCheck']),
    selectedOption() {
      return this.options.find((option) => option.isSelected)
    },
    dropdownOptions() {
      return this.options.filter((option) => !option.isSelected && this.hasPermission(option.permission))
    }
  },
  methods: {
    toggle(action) {
      this.isExpanded = !this.isExpanded
      if (!this.isCompanionUser && action === 'companion') {
        this.$mxp.track('dashboard_upsell-companion')
        this.$modal.open('companion/UpsellCompanion')
      } else if (!this.hasAutoCheck && action === 'check') {
        this.$mxp.track('dashboard_upsell-autocheck')
        this.$modal.open('companion/UpsellAutocheck')
      } else {
        this.$mxp.track(`dashboard_toggle_${action}`)
        this.$emit('change', action)
      }
    },
    hasPermission(permission) {
      return permission === true || this[permission]
    }
  }
}
</script>

<style lang="sass" scoped>
.collapse
  border-bottom: solid 1px $border-background
.is-up
  transform: scaleY(-1)
  transition: transform 0.2s ease
.is-down
  transform: scaleY(1)
  transition: transform 0.2s ease
</style>
